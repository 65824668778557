@tailwind base;
@tailwind components;

// // Tools
@import "tools/_mixins.scss";

// // Menu
@import "menu/top_bar.scss";
@import "menu/main_menu.scss";
@import "menu/mobile_menu.scss";

// // Components
@import "components/menu_widget.scss";
@import "components/home-relax-section.scss";
@import "components/article-block.scss";
@import "components/assortment.scss";
@import "components/bg.scss";
@import "components/breadcrumb.scss";
@import "components/button.scss";
@import "components/category-menu.scss";
@import "components/choice-help.scss";
@import "components/services-row.scss";
@import "components/checkmark-list.scss";
@import "components/custom-select.scss";
@import "components/dealers.scss";
@import "components/emblem.scss";
@import "components/faq.scss";
@import "components/footer.scss";
@import "components/forms.scss";
@import "components/header.scss";
@import "components/hero.scss";
@import "components/highlighted-page.scss";
@import "components/highlighted-page-v2.scss";
@import "components/icons.scss";
@import "components/img-hover.scss";
@import "components/img-overlay.scss";
@import "components/list-link.scss";
@import "components/localisation.scss";
@import "components/pagination.scss";
@import "components/product-item.scss";
@import "components/product-overview.scss";
@import "components/product-row.scss";
@import "components/product-detail.scss";
@import "components/home-product-quickmenu.scss";
@import "components/page-overview.scss";
@import "components/range.scss";
@import "components/responsive-embed.scss";
@import "components/sections.scss";
@import "components/speclist.scss";
@import "components/steps-list.scss";
@import "components/tooltip.scss";
@import "components/typography.scss";
@import "components/usage-type.scss";
@import "components/label-usage-type.scss";
@import "components/usps.scss";
@import "components/loader.scss";
@import "components/video.scss";
@import "components/rating.scss";
@import "components/in-stock.scss";
@import "components/price.scss";
@import "components/info-icon.scss";
@import "components/certifications-imglist.scss";
@import "components/search.scss";
@import "components/downloads.scss";
@import "components/filter-item.scss";
@import "components/commerce-connector.scss";
@import "components/image-text.scss";
@import "components/text-columns.scss";
@import "components/regulations.scss";
@import "components/raster-overview.scss";
@import "components/partslist.scss";
@import "components/dotted-underline.scss";
@import "components/store-button.scss";
@import "components/store-filter.scss";
@import "components/cta-bar.scss";
@import "components/blog-filter.scss";
@import "components/link-list.scss";
@import "components/meta.scss";
@import "components/image-slider.scss";
@import "components/about.scss";
@import "components/range-slider.scss";
@import "components/product-filter-tabs.scss";
@import "components/modal.scss";

@import "@glidejs/glide/src/assets/sass/glide.core.scss";
@import "@glidejs/glide/src/assets/sass/glide.theme";

html,
body {
    &.noscroll {
        height: 100vh;
        overflow: hidden;
    }
}

.wrapped-content {
    > *:last-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.site-container {
    /* AL-656: required to prevent :after separator from overflowing on smaller viewports when search is expanded */
    overflow-x: hidden;
    word-break: break-word;
    max-width: 1920px;
    margin: 0 auto;
    .gap-8 {
        gap: 1rem;
    }
    .gap-x-8 {
        -moz-column-gap: 1rem;
        column-gap: 1rem;
    }
}

details {
    > summary {
        list-style: none;

        &::-webkit-details-marker {
            display: none;
        }
    }
}

.glide__bullets {
    right: 1em;
    left: auto;

    @screen md {
        right: 3em;
        bottom: 4em;
    }

    .glide__bullet--active,
    .glide__bullet:hover,
    .glide__bullet:focus {
        @apply bg-primary border-primary;
    }
}

.glide__arrows {
    .glide__arrow {
        @apply bg-dark-200 border-none rounded-none px-2 py-4 lg:px-4 lg:py-12 opacity-90 hover:opacity-100;

        &.glide__arrow--prev {
            @apply rounded-r-md;
            left: 0;
        }

        &.glide__arrow--next {
            @apply rounded-l-md;
            right: 0;
        }
    }
}

body {
    @apply text-dark-200 font-museo;
}

a a.btn * {
    @apply font-montserrat;
    font-weight: 400;

    &.h2 {
        display: inline-block;
        @apply font-museo;
    }
}

a * {
    @apply font-museo;
}

body,
html {
    &.scroll-lock {
        overflow-y: hidden;
    }
    overflow-x: hidden;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: none;
}

.container {
    max-width: 1440px !important;
}

table {
    @apply w-full;
}

.table {
    @apply w-full mb-6;

    th {
        text-align: left;
        background-color: #f0f0f0;
    }

    th,
    td {
        @apply p-2 border border-black border-opacity-20 w-1/2;
    }
}

.inline-list {
    @apply my-6 ml-8;

    &.inline-list--indent-0 {
        @apply ml-4;
    }

    &.inline-list--tight {
        li {
            @apply my-0;
        }
    }

    li {
        @apply my-2;

        &::before {
            @apply text-primary font-bold inline-block w-4 -ml-4;
            content: "\2022";
            margin-left: -15px;
            width: 12px;
        }

        .inline-list {
            @apply my-2 ml-8;
        }
    }
}

@keyframes lcpfadein {
    from {
        opacity: 0;
    }

    0.000001% {
        opacity: 1;
    }

    to {
        opacity: 1;
    }
}

.lcpimage {
    opacity: 0;
}

[x-cloak] { display: none !important; }
